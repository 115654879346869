@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .gradient_pink_purple_text {
        background: linear-gradient(84deg, #E4077D 0%, #6226A7 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .gradient_red_orange_text {
        background: linear-gradient(270deg, #EF7F21 0%, #E42025 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bg_gradient_pink_purple{
        background:  linear-gradient(84deg, #E4077D 0%, #6226A7 100%);
    }
}