@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');



body {
  font-family: 'Montserrat', sans-serif;
  background-color: #181818;
  color: #FFFFFF;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #E4146E;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff1077;
}

.login_wrapper {
  background-image: url('./assets/images/Login.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;

}

.login_wrapper {
  position: relative;
  width: 100%;
  min-height: 700px;
  height:100vh ;
  background-color: black;
}

.top_middle,
.bottom_left,
.bottom_right {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}

.top_middle {
  top: 0;
  left: 275px;
  right: 0;
  background-image: url('./assets/images/top_middle.svg');
  width: 505px;
  height: 181px
}

.bottom_left {
  bottom: 0;
  left: 0;
  background-image: url('./assets/images/bottom_left.svg');
  width: 162px;
  height: 159px;
}

.bottom_right {
  bottom: 42px;
  right: 30px;
  background-image: url('./assets/images/bottom_right.svg');
  width: 230px;
  height: 250px;
}

.Login_logo {
  position: absolute;
  right: 10%;
  /* top: 50%;
    transform: translateY(50%); */
}

.login_form_wrapper {
  border-radius: 32px;
  background: radial-gradient(129.62% 164.52% at 106.15% 5.08%, rgba(0, 0, 0, 0.08) 11.75%, rgba(98, 38, 167, 0.10) 44.3%, rgba(228, 7, 125, 0.10) 86.9%);
  backdrop-filter: blur(10px);
  height: 100%;
  /* width: 50%; */
  max-width: 700px;
  padding: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.gradient_text_small {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--gradient-red-orange, linear-gradient(270deg, #EF7F21 0%, #E42025 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient_button {
  border-radius: 8px;
  background: linear-gradient(90deg, #78BE20 0%, #0C9DD9 100%);
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  /* 150% */
  text-transform: uppercase;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #2d232f inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

.vertical_heading {
  writing-mode: tb;
  rotate: -180deg;
}

@media screen and (max-width: 768px) {

  .top_middle,
  .bottom_left,
  .bottom_right {
    display: none;
  }

  /* .login_wrapper{
        height: unset;
    } */
  .login_form_wrapper {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
  }
}



.btnColor {
  border-radius: 8px;
  border: 1px solid var(--gradient-red-orange, #EF7F21);
  background: linear-gradient(270deg, rgba(239, 127, 33, 0.20) 0%, rgba(228, 32, 37, 0.20) 100%);
  padding: 9px;
}

.btnColorLayout {
  display: flex;
  flex-wrap: wrap;
  /* height: 32px; */
  padding: 10px 12px;
  align-items: center;
  gap: 12px;
}


.btnEditPermissionLayout {
  color: #FFF;
  text-align: center;
  font-family: Gotham;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  /* 150% */
  text-transform: uppercase;
}

.btnEditPermission {
  border-radius: 8px;
}


.EditBtnLayout {
  width: 24px;
  height: 24px
}


.HomeBreadcrump {
  color: #797979;
  text-align: center;
  font-family: Gotham;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}


.EditPermissionText {
  background: var(--gradient-pink-purple, linear-gradient(84deg, #E4077D 0%, #6226A7 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: larger
}


.BreadCrumpAddCustomer {

  display: flex;
  justify-content: space-between;



}